.app{
    max-width: 1380px;
    margin: 0 auto;
    padding: 1% 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-container{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
}

.random-button{
    font-size: 18px;
    text-transform: capitalize;
    border-radius: 1rem;
    padding: .5rem .7rem;
    cursor: pointer;
    box-shadow: -1px 1px 1px #000;
    transition: all .05s ease-in-out;
    margin-right: 15px;
}

.random-button:hover{
    background-color: #ccc;
}

.random-button:active{
    box-shadow: none;
}

.title{
    font-size: 2.2rem;
    line-height: 2;
    letter-spacing: 2px;
}

.quote-container{
    width: 70%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.list-quotes{
    width: 70%;
}

.quote-text{
    font-size: 2rem;
    line-height: 2rem;
    margin: 1% 0;
    padding: 5% 10%;
    border-left: 10px solid #F7DF94;
}

.quote-author{
    width: 100%;
    padding: 5% 10%;
    font-size: 1.5rem;
    transition: all .3s ease-in-out;
}

.quote-author:hover{
    background-color: #000;
    color: #fff;
    cursor: pointer;
}

.author{
    margin: 1% 0;
}

.author:hover{
    text-decoration: underline;
}

.author-title{
    margin: 1% 0;
}

.author-title:hover span:hover{
    text-decoration: underline;
}
